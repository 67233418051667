import React, { useEffect, useState } from "react";
import { ResponsiveContainer } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Chart({ width, height, fetch, period, children, reload }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    setLoading(true);

    try {
      const data = await fetch();
      setData(data);
    } catch (err) {
      console.error("Error fetching metrics", err);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [period, reload]);

  return (
    <div
      style={{
        width,
        height,
      }}
    >
      {!loading && !data?.data?.length && (
        <div className="h-100 d-flex justify-content-center align-items-center">
          <p>No data available</p>
        </div>
      )}
      <div
        style={{
          position: "relative",
          textAlign: "center",
        }}
      >
        {loading && (
          <div className="h-100 d-flex justify-content-center align-items-center">
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width,
                height,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(255, 255, 255, 0.5)",
                zIndex: 10,
                transition: "opacity 0.5s ease-in-out",
              }}
            >
              <FontAwesomeIcon
                className="text-secondary"
                size="2x"
                icon="spinner"
                spin
              />
            </div>
          </div>
        )}

        {data?.data?.length > 0  && (
          <div style={{ position: "relative" }}>
            <ResponsiveContainer width={width} height={height}>
              {children({ data })}
            </ResponsiveContainer>
          </div>
        )}
      </div>
    </div>
  );
}

export const ChartContainer = ({ children }) => (
  <div className="border rounded py-2 px-2 mb-2">{children}</div>
);

export const periods = [
  { value: 5, unit: "minutes", displayValue: "5 min" },
  { value: 15, unit: "minutes", displayValue: "15 min" },
  { value: 30, unit: "minutes", displayValue: "30 min" },
  { value: 1, unit: "hours", displayValue: "1 hour" },
  { value: 3, unit: "hours", displayValue: "3 hours" },
  { value: 6, unit: "hours", displayValue: "6 hours" },
  { value: 24, unit: "hours", displayValue: "24 hours" },
];

const formatTooltipTime = (timestamp) => {
  const date = new Date(timestamp)

  // Get the year, month, day, hours, minutes, and seconds
  const year = date.getFullYear().toString();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-based
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Return formatted date and time
  return `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
};

export function TimeseriesTooltip({ active, payload, children }) {
  if (active && payload && payload.length) {
    const time = payload[0]?.payload?.time;
    const label = time && formatTooltipTime(time);

    return (
      <div
        style={{
          padding: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
        }}
      >
        <p>{label}</p>
        {children({ payload })}
      </div>
    );
  }

  return null;
}

function getTimeRange(period) {
  // Get current date and time as end time
  const endTime = new Date();
  const startTime = new Date(endTime);

  const { value, unit } = period;

  // TODO: HV, improve resolution calculation based on the time range.
  // Step format can be golang duration or float number of seconds.
  // Use number of seconds so the value can be used in javascript.
  let step = 10;

  switch (unit) {
    case "minutes":
      startTime.setMinutes(startTime.getMinutes() - value);
      step = 10;
      break;
    case "hours":
      startTime.setHours(startTime.getHours() - value);
      step = 60;
      break;
    default:
      throw new Error(`Unknown time unit: ${unit}`);
  }

  return [startTime, endTime, step];
}

// Fetch metrics from the dashboard API.
export async function fetchMetrics(name, period, params) {
  try {
    const [startTime, endTime, step] = getTimeRange(period);

    const queryParams = new URLSearchParams({
      ...params,
      start: startTime.getTime() / 1000,
      end: endTime.getTime() / 1000,
      step,
    }).toString();

    const response = await fetch(`/api/metrics/${name}?${queryParams}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    if (result.status === "success") {
      const range = {
        startTime: startTime.getTime(),
        endTime: endTime.getTime(),
        step: step * 1000,
      }
      const chartData = mapPrometheusData(result, range);
      return chartData;
    } else {
      console.error("Error fetching data:", result.error);
    }
  } catch (err) {
    console.error("Fetch error:", err);
  }
}

// Fetch metrics directly from Prometheus.
// This function should only be used for development to quickly
// iterate on the query expression without restarting the backend.
export async function fetchMetricsDirect(expr, period) {
  try {
    const prometheusURL = "http://127.0.0.1:9090/api/v1/query_range";
    const [startTime, endTime, step] = getTimeRange(period);

    const queryParams = new URLSearchParams({
      query: expr,
      start: startTime.getTime() / 1000,
      end: endTime.getTime() / 1000,
      step,
    }).toString();

    const response = await fetch(`${prometheusURL}?${queryParams}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    if (result.status === "success") {
      const range = {
        startTime: startTime.getTime(),
        endTime: endTime.getTime(),
        step: step * 1000,
      }
      const chartData = mapPrometheusData(result, range);
      return chartData;
    } else {
      console.error("Error fetching data:", result.error);
    }
  } catch (err) {
    console.error("Fetch error:", err);
  }
}

export function formatAxisTime(period, timestamp) {
  const time = new Date(timestamp)

  switch (period.unit) {
    case "minutes":
      return time.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
    case "hours":
      return time.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: false,
      });
  }
}

// Predefined set of contrast-safe colors
export const colors = {
  green: "#7EB26D",
  yellow: "#EAB839",
  cyan: "#6ED0E0",
  orange: "#EF843C",
  red: "#E24D42",
  blue: "#1F78C1",
  magenta: "#BA43A9",
  purple: "#705DA0",
  darkGreen: "#508642",
};

export function deterministicColorFromSet(key) {
  const colorSet = Object.values(colors);

  // Simple hash function to generate a numeric index
  let hash = 0;
  for (let i = 0; i < key.length; i++) {
    hash = (hash << 5) - hash + key.charCodeAt(i);
    hash |= 0; // Convert to 32bit integer
  }

  // Map hash to the color set
  const index = Math.abs(hash) % colorSet.length;

  return colorSet[index];
}

export function mapPrometheusData(result, range) {
  const resultType = result.data.resultType;
  const mapValues = new Map();
  const metrics = result.data.result.map((result) => result.metric);
  result.data.result.forEach((result, index) => {
    result.values.forEach(([timestamp, rawValue]) => {
      let value = parseFloat(rawValue);
      if (isNaN(value)) {
        value = null;
      }

      let v = mapValues.get(timestamp);
      if (v) {
        v[index] = value;
      } else {
        v = [];
        v[index] = value;
        mapValues.set(timestamp, v);
      }
    });
  });

  let data = [];
  for (const [timestamp, values] of mapValues) {
    data.push({
      time: new Date(timestamp * 1000).getTime(),
      values,
    });
  }

  if (data.length > 0) {
    data = fillTimeSeriesFromRange(data, range)
  } 

  return { metrics, data, range };
}

function fillTimeSeriesFromRange(data, range) {
  const { startTime, endTime, step } = range;

  const series = new Map(data.map((entry) => [entry.time, entry.values]));

  // Create an array to hold the filled time series
  const filledSeries = [];

  // Generate timestamps from startTime to endTime
  for (let timestamp = startTime; timestamp <= endTime; timestamp += step) {
    // Check if the current timestamp exists in the series
    if (series.has(timestamp)) {
      filledSeries.push({ time: timestamp, values: series.get(timestamp) });
    } else {
      filledSeries.push({ time: timestamp, values: [] });
    }
  }

  return filledSeries
}
