import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
} from "recharts";

import {
  Card,
  CardHeader,
  CardBody,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Tooltip,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";

import {
  Chart,
  TimeseriesTooltip,
  periods,
  colors,
  fetchMetrics,
  formatAxisTime,
  deterministicColorFromSet,
  ChartContainer,
} from "../components/Chart";

const defaultChartProps = {
  margin: { top: 20, right: 10, left: 10, bottom: 5 },
};

const defaultAreaProps = {
  type: "monotone",
  strokeWidth: 2,
  strokeOpacity: 0.5,
  fillOpacity: 0.1,
  isAnimationActive: false,
};

const PeriodSelector = ({ values, defaultValue, onSelect, className }) => {
  const [selected, setSelected] = useState(defaultValue || values[0]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onSelect && onSelect(selected);
  }, [selected]);

  return (
    <ButtonDropdown
      size="xs"
      isOpen={open}
      toggle={() => setOpen((prevState) => !prevState)}
      className={className}
    >
      <DropdownToggle
        outline
        caret
        style={{ height: "100%", fontSize: "14px" }}
      >
        {selected.displayValue}
      </DropdownToggle>
      <DropdownMenu>
        {values.map((value) => (
          <DropdownItem
            key={value.displayValue}
            onClick={() => setSelected(value)}
          >
            {value.displayValue}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

const InfoTooltip = ({ className, children, id }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className={className}>
      <FontAwesomeIcon
        icon={faQuestionCircle}
        id={id}
        className="text-muted"
        style={{ height: "13px", width: "13px" }}
      />

      <Tooltip
        placement="top"
        isOpen={tooltipOpen}
        autohide={false}
        target={id}
        toggle={() => setTooltipOpen((prev) => !prev)}
        style={{ backgroundColor: "#6c757d" }}
        className="p-30"
      >
        {children}
      </Tooltip>
    </div>
  );
};

export function GraphPage(props) {
  const { namespace, functionName } = props.match.params;
  const [period, setPeriod] = useLocalStorage("metrics-period", periods[0]);
  const [reloadTrigger, setReloadTrigger] = useState(0);

  const [activeTab, setActiveTab] = useLocalStorage(
    "metrics-tab",
    "invocations"
  );

  const isActiveTab = (tabId) => activeTab == tabId;

  const reloadData = () => setReloadTrigger((prev) => prev + 1);

  return (
    <Card outline color="success">
      <CardHeader className="bg-success color-success flex align-items-center justify-content-between">
        <div>Metrics from your function</div>
        <div className="flex">
          <PeriodSelector
            className="mr-2"
            values={periods}
            defaultValue={period}
            onSelect={(val) => setPeriod(val)}
          />
          <Button outline size="xs" title="reload graphs" onClick={reloadData}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </div>
      </CardHeader>
      <CardBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={isActiveTab("invocations") ? "active" : ""}
              onClick={() => setActiveTab("invocations")}
              style={{ cursor: "pointer" }}
            >
              Invocations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={isActiveTab("load") ? "active" : ""}
              onClick={() => setActiveTab("load")}
              style={{ cursor: "pointer" }}
            >
              Load
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={isActiveTab("resources") ? "active" : ""}
              onClick={() => setActiveTab("resources")}
              style={{ cursor: "pointer" }}
            >
              Resources
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} className="flex flex-column py-4">
          <TabPane tabId="invocations">
            <ChartContainer>
              <div className="flex align-items-center">
                <p className="mb-0 is-bold">Invocation rate by status code</p>
                <InfoTooltip id="invocation-info" className="ml-2">
                  Function invocation rate over the last 30s broken down by
                  status code.
                </InfoTooltip>
              </div>
              <InvocationrateGraph
                period={period}
                namespace={namespace}
                functionName={functionName}
                reload={reloadTrigger}
              />
            </ChartContainer>
            <ChartContainer>
              <div className="flex align-items-center">
                <p className="mb-0 is-bold">Latency by status code</p>
                <InfoTooltip id="latency-info" className="ml-2">
                  Average function invocation latency over the last 30s broken
                  down by status code.
                </InfoTooltip>
              </div>
              <LatencyGraph
                period={period}
                namespace={namespace}
                functionName={functionName}
                reload={reloadTrigger}
              />
            </ChartContainer>
          </TabPane>
          <TabPane tabId="load">
            <ChartContainer>
              <div className="flex align-items-center">
                <p className="mb-0 is-bold">Load</p>
                <InfoTooltip id="load-info" className="ml-2">
                  Load as measured by the autoscaler.
                </InfoTooltip>
              </div>
              <LoadGraph
                period={period}
                namespace={namespace}
                functionName={functionName}
                reload={reloadTrigger}
              />
            </ChartContainer>
            <ChartContainer>
              <div className="flex align-items-center">
                <p className="mb-0 is-bold">Replicas</p>
                <InfoTooltip id="replicas-info" className="ml-2">
                  Function replica count.
                </InfoTooltip>
              </div>
              <ReplicaGraph
                period={period}
                namespace={namespace}
                functionName={functionName}
                reload={reloadTrigger}
              />
            </ChartContainer>
          </TabPane>
          <TabPane tabId="resources">
            <ChartContainer>
              <div className="flex align-items-center">
                <p className="mb-0 is-bold">CPU usage</p>
                <InfoTooltip id="cpu-info" className="ml-2">
                  CPU usage of the function across all replicas, measured in milli-CPU.
                </InfoTooltip>
              </div>
              <CpuGraph
                period={period}
                namespace={namespace}
                functionName={functionName}
                reload={reloadTrigger}
              />
            </ChartContainer>
            <ChartContainer>
              <div className="flex align-items-center">
                <p className="mb-0 is-bold">Memory usage</p>
                <InfoTooltip id="memory-info" className="ml-2">
                  Memory usage of the function across all replicas.
                </InfoTooltip>
              </div>
              <MemoryGraph
                period={period}
                namespace={namespace}
                functionName={functionName}
                reload={reloadTrigger}
              />
            </ChartContainer>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
}

const LatencyTooltip = (props) => (
  <TimeseriesTooltip {...props}>
    {({ payload }) => (
      <ul
        style={{
          listStyleType: "none", // Removes the default list dots
          paddingLeft: 0, // Removes the left padding
          textAlign: "left",
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name} - ${formatDuration(entry.value)}`}
          </li>
        ))}
      </ul>
    )}
  </TimeseriesTooltip>
);

function LatencyGraph({ period, namespace, functionName, reload }) {
  return (
    <Chart
      width="100%"
      height={350}
      period={period}
      fetch={() =>
        fetchMetrics("latency", period, {
          function: functionName,
          namespace: namespace,
        })
      }
      reload={reload}
    >
      {({ data }) => {
        return (
          <AreaChart {...defaultChartProps} data={data.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={({ time }) => formatAxisTime(period, time)} />
            <YAxis />
            <ChartTooltip content={LatencyTooltip} />
            <Legend />
            {data.metrics.map((metric, metricIndex) => (
              <Area
                {...defaultAreaProps}
                key={metricIndex}
                dataKey={(data) => data.values[metricIndex]}
                name={`${metric.code}`}
                stroke={deterministicColorFromSet(metric.code)}
                fill={deterministicColorFromSet(metric.code)}
              />
            ))}
          </AreaChart>
        );
      }}
    </Chart>
  );
}

const InvocationrateTooltip = (props) => (
  <TimeseriesTooltip {...props}>
    {({ payload }) => (
      <ul
        style={{
          listStyleType: "none", // Removes the default list dots
          paddingLeft: 0, // Removes the left padding
          textAlign: "left",
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name} - ${entry.value.toFixed(3)}`}
          </li>
        ))}
      </ul>
    )}
  </TimeseriesTooltip>
);

function InvocationrateGraph({ period, namespace, functionName, reload }) {
  return (
    <Chart
      width="100%"
      height={350}
      period={period}
      fetch={() =>
        fetchMetrics("invocations", period, {
          function: functionName,
          namespace: namespace,
        })
      }
      reload={reload}
    >
      {({ data }) => {
        return (
          <AreaChart {...defaultChartProps} data={data.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={({ time }) => formatAxisTime(period, time)} />
            <YAxis />
            <ChartTooltip content={<InvocationrateTooltip />} />
            <Legend />
            {data.metrics.map((metric, metricIndex) => (
              <Area
                {...defaultAreaProps}
                key={metricIndex}
                dataKey={(data) => data.values[metricIndex]}
                name={`${metric.code}`}
                stroke={deterministicColorFromSet(metric.code)}
                fill={deterministicColorFromSet(metric.code)}
              />
            ))}
          </AreaChart>
        );
      }}
    </Chart>
  );
}

const ReplicaTooltip = (props) => (
  <TimeseriesTooltip {...props}>
    {({ payload }) => (
      <ul
        style={{
          listStyleType: "none", // Removes the default list dots
          paddingLeft: 0, // Removes the left padding
          textAlign: "left",
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name} - ${Math.floor(entry.value)}`}
          </li>
        ))}
      </ul>
    )}
  </TimeseriesTooltip>
);

function ReplicaGraph({ period, namespace, functionName, reload }) {
  return (
    <Chart
      width="100%"
      height={350}
      period={period}
      fetch={() =>
        fetchMetrics("replicas", period, {
          function: functionName,
          namespace: namespace,
        })
      }
      reload={reload}
    >
      {({ data }) => {
        return (
          <AreaChart {...defaultChartProps} data={data.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={({ time }) => formatAxisTime(period, time)} />
            <YAxis />
            <ChartTooltip content={<ReplicaTooltip />} />
            <Legend />
            {data.metrics.map((metric, metricIndex) => (
              <Area
                {...defaultAreaProps}
                key={metricIndex}
                dataKey={(data) => data.values[metricIndex]}
                name={`${metric.function_name}`}
                stroke={colors.blue}
                fill={colors.blue}
              />
            ))}
          </AreaChart>
        );
      }}
    </Chart>
  );
}

const LoadTooltip = (props) => (
  <TimeseriesTooltip {...props}>
    {({ payload }) => (
      <ul
        style={{
          listStyleType: "none", // Removes the default list dots
          paddingLeft: 0, // Removes the left padding
          textAlign: "left",
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name} - ${entry.value}`}
          </li>
        ))}
      </ul>
    )}
  </TimeseriesTooltip>
);

function LoadGraph({ period, namespace, functionName, reload }) {
  return (
    <Chart
      width="100%"
      height={350}
      period={period}
      fetch={() =>
        fetchMetrics("load", period, {
          function: functionName,
          namespace: namespace,
        })
      }
      reload={reload}
    >
      {({ data }) => {
        return (
          <AreaChart {...defaultChartProps} data={data.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={({ time }) => formatAxisTime(period, time)} />
            <YAxis />
            <ChartTooltip content={<LoadTooltip />} />
            <Legend />
            {data.metrics.map((metric, metricIndex) => (
              <Area
                {...defaultAreaProps}
                key={metricIndex}
                dataKey={(data) => data.values[metricIndex]}
                name={`${metric.scaling_type}`}
                stroke={colors.blue}
                fill={colors.blue}
              />
            ))}
          </AreaChart>
        );
      }}
    </Chart>
  );
}

const CpuTooltip = (props) => (
  <TimeseriesTooltip {...props}>
    {({ payload }) => (
      <ul
        style={{
          listStyleType: "none", // Removes the default list dots
          paddingLeft: 0, // Removes the left padding
          textAlign: "left",
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`CPU - ${formatMillicores(entry.value)}`}
          </li>
        ))}
      </ul>
    )}
  </TimeseriesTooltip>
);

function CpuGraph({ period, namespace, functionName, reload }) {
  return (
    <Chart
      width="100%"
      height={350}
      period={period}
      fetch={() =>
        fetchMetrics("cpu", period, {
          function: functionName,
          namespace: namespace,
        })
      }
      reload={reload}
    >
      {({ data }) => {
        return (
          <AreaChart {...defaultChartProps} data={data.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={({ time }) => formatAxisTime(period, time)} />
            <YAxis />
            <ChartTooltip content={<CpuTooltip />} />
            <Legend />
            {data.metrics.map((metric, metricIndex) => (
              <Area
                {...defaultAreaProps}
                key={metricIndex}
                dataKey={(data) => data.values[metricIndex]}
                name={`${metric.function_name}`}
                stroke={colors.blue}
                fill={colors.blue}
              />
            ))}
          </AreaChart>
        );
      }}
    </Chart>
  );
}

const MemoryTooltip = (props) => (
  <TimeseriesTooltip {...props}>
    {({ payload }) => (
      <ul
        style={{
          listStyleType: "none", // Removes the default list dots
          paddingLeft: 0, // Removes the left padding
          textAlign: "left",
        }}
      >
        {payload.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`Memory - ${formatBytes(entry.value)}`}
          </li>
        ))}
      </ul>
    )}
  </TimeseriesTooltip>
);

function MemoryGraph({ period, namespace, functionName, reload }) {
  return (
    <Chart
      width="100%"
      height={350}
      period={period}
      fetch={() =>
        fetchMetrics("memory", period, {
          function: functionName,
          namespace: namespace,
        })
      }
      reload={reload}
    >
      {({ data }) => {
        return (
          <AreaChart {...defaultChartProps} data={data.data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={({ time }) => formatAxisTime(period, time)} />
            <YAxis tickFormatter={(value) => formatBytes(value, 0)} />
            <ChartTooltip content={<MemoryTooltip />} />
            <Legend />
            {data.metrics.map((metric, metricIndex) => (
              <Area
                {...defaultAreaProps}
                key={metricIndex}
                dataKey={(data) => data.values[metricIndex]}
                name={`${metric.function_name}`}
                stroke={colors.blue}
                fill={colors.blue}
              />
            ))}
          </AreaChart>
        );
      }}
    </Chart>
  );
}

export function formatDuration(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  const integerSeconds = Math.floor(s);
  const fractionalPart = s - integerSeconds;

  const parts = [];

  if (h === 0 && m === 0 && integerSeconds === 0 && fractionalPart > 0) {
    const milliseconds = Math.floor(fractionalPart * 1000);
    const microseconds = Math.floor((fractionalPart * 1000000) % 1000);

    if (milliseconds > 0) parts.push(`${milliseconds}ms`);
    else if (microseconds > 0) parts.push(`${microseconds}µs`);
  }

  if (h === 0 && m === 0 && parts.length === 0) {
    parts.push(`${s.toFixed(2)}s`);
  }

  if (h > 0) parts.push(`${h}h`);
  if (m > 0) parts.push(`${m}m`);
  if ((h != 0 && m != 0 && integerSeconds > 0) || parts.length === 0)
    parts.push(`${integerSeconds}s`);

  return parts.join("");
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024; // binary equivalent of the decimal "1000"
  const sizes = [
    "Bytes",
    "KiB",
    "MiB",
    "GiB",
    "TiB",
    "PiB",
    "EiB",
    "ZiB",
    "YiB",
  ];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + sizes[i];
}

function formatMillicores(usage) {
  const millicores = usage * 1000;
  return `${millicores.toFixed(2)}m`;
}
